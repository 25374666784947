export interface Page<T> {
  pagina: number;
  paginasTotales: number;
  content: T[];
}

export interface SelectOption {
  label: string;
  value: string;
}

export enum ActivityType {
  ANUAL='anual', VERANO='verano', PUNTUAL='puntual'
}

export enum SizeEnum {
  S, M, B, VB
}

export enum Section {
  HOME, ACTIVIDADES, REGISTRO, BAJAS, FORMULARIO, DETALLE_INSCRIPCION
}

export enum Category {
  PREBENJAMIN, BENJAMIN, ALEVIN, INFANTIL, ADULTO
}

export interface UserDataStored {
  userId: number;
  username: string;
  rol: RolEnum;
}

export enum RolEnum {
  MONITOR = 'MO',
  COORDINADOR = 'CO',
}

export type DataTuple = [string, string];


