import React, { } from 'react';
import { useSetRecoilState } from 'recoil';
import { sectionAtom } from '../../atoms/MainAtoms';
import { useApi } from '../../hooks/useApi';
import { Section } from '../../types/types';
import './../../index.css'
import { useAuth } from '../../hooks/useAuth';
import { AiOutlineForm } from 'react-icons/ai'
import { FiList } from 'react-icons/fi'

export function Menu() {


    const setSection = useSetRecoilState(sectionAtom)
    const { isAuth } = useAuth()

    return (
        <>
            {isAuth ?
                <div className={'menuContent'}>

                    <div className={'menuOption'} onClick={() => setSection(() => Section.ACTIVIDADES)}><AiOutlineForm /><span className={'optionText'}>{'Apuntarse a actividades'}</span></div>
                    <div className={'menuOption'} onClick={() => setSection(() => Section.BAJAS)}><FiList /><span className={'optionText'}>{'Mis actividades'}</span></div>
                </div>

                :
                <>
                    <div className='noLoginMessage'>{'Para acceder a esta página, debe tener una cuenta de usuario'}</div>
                    <button className='registerBut' onClick={() => setSection(Section.REGISTRO)}>{'Registrarse'}</button>
                </>
            }
        </>
    )
}