import React from 'react';
import { useRecoilState } from 'recoil';
import { ConfirmationModalType, confirmationModalInfo } from 'src/atoms/MainAtoms';

export function ConfirmationModal() {


    const [modalInfo, setModalInfo] = useRecoilState<ConfirmationModalType>(confirmationModalInfo)


    const onAccept = async () => {
        await modalInfo.executeFun()
        onReset()
    }
    const onReset = () => {
        setModalInfo(() => { return { executeFun: () => false, text: '', visible: false } })
    }

    return (
        <>
            {
                modalInfo.visible ?
                    <div className='modalBackground'>
                        <div className='modal'>
                            <p>{modalInfo.text}</p>
                            <div className='buttonsModal'>
                                <button onClick={onReset}>{'Cancelar'}</button>
                                <button onClick={onAccept}>{'Aceptar'}</button>
                            </div>
                        </div>
                    </div>
                    :
                    undefined
            }
        </>
    )
}