import { useEffect, useState } from 'react';
import { useSetRecoilState } from 'recoil';
import { selectedActivityAtom } from '../../atoms/MainAtoms';
import { useApi } from '../../hooks/useApi';
import { Activity } from '../../types/entities';
import { ActivityType } from '../../types/types';
import { utilMethods } from '../../utils/utilMethods';
import { ActivityItem } from '../atom/ActivityItem';
import { Paginacion } from '../molecule/Paginacion';
import './../../index.css'

export function ActivitySelector() {

    const [page, setPage] = useState<number>(0)
    const [totalPages, setTotalPages] = useState<number>(0)
    const [activityType, setActivityType] = useState<string>(ActivityType.ANUAL)
    const [activityList, setActivityList] = useState<Activity[]>([])
    const [totalElements, setTotalElements] = useState<number>(0)
    const setSelectedActivity = useSetRecoilState<Activity | undefined>(selectedActivityAtom)

    const { getActivities, countRegularActivities } = useApi()

    useEffect(() => {
        setSelectedActivity(() => undefined)
    }, [])


    useEffect(() => {
        onGetActivities()
    }, [page, activityType, totalElements])

    const onGetActivities = async () => {
        const totalElements = await countRegularActivities()
        setTotalElements(() => totalElements)
        const activityPage = await getActivities(activityType, page, totalElements)
        setActivityList(() => activityPage.content)
        setTotalPages(() => activityPage.paginasTotales)
    }

    return (
        <>
            <div className={'activitySelectorContent'}>
                <div className={'selectorBox'}>
                    <select className={'select'} value={activityType} onChange={(e) => setActivityType(() => e.target.value)}>
                        {Array.from(Object.values(ActivityType).map(
                            (type) => <option key={type} value={type}>{utilMethods.getActivityTypeLabel(type)}</option>))}
                    </select>
                    <div className={'activityList'}>
                        {activityList.length > 0 ?
                            activityList.map((activity) => <ActivityItem activity={activity} />)
                            :
                            <p className='noElements'>{'No hay actividades en esta categoría'}</p>
                        }
                    </div>
                    <Paginacion page={page} setPage={setPage} totalPages={totalPages} />
                </div>
            </div>
        </>
    )
}