import React, { useRef, useState } from "react";
import { AiOutlineDownload } from "react-icons/ai";
import { RiDeleteBack2Line } from 'react-icons/ri'

export function FileField({ id, label, value, setValue, downFile}: { id: string, label: string, value: string | undefined, setValue: React.Dispatch<React.SetStateAction<File | undefined>> | undefined, downFile: string }) {

    const [fileName, setFileName] = useState<string | undefined>(undefined)

    const fileInputRef = useRef<HTMLInputElement>(null)


    const onFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (setValue) {
            if (e?.target?.files !== null) {
                let file: File = new File([e.target.files[0]], downFile, { type: 'application/pdf' });
                setValue(file);
            } else {
                setValue(undefined);
            }
        }
    }

    const onReset = async () => {
        if (fileInputRef?.current) {
            fileInputRef.current.value = ''
        }
    }
    const onDownloadFile = () => {
        if (value) {
            const linkSource = `data:application/pdf;base64,${value}`;
            console.log(value)
            const downloadLink = document.createElement("a");
            
            downloadLink.href = linkSource;
            downloadLink.download = downFile;
            downloadLink.click();
        }
    }

    return (
        <div className={'fileFieldBox'}>
            <label htmlFor={id}>{label}</label>
            <div className='fieldAndBut'>
                {value ?
                    <div className="downloadAuthBox">
                        <div className="fileName">{downFile}</div>
                        <button onClick={onDownloadFile}><AiOutlineDownload/></button>
                    </div>
                    :
                    <input id={id} disabled={setValue ? false : true}
                        type={'file'} onChange={onFileChange} ref={fileInputRef}></input>
                }
            </div>
        </div >
    )
}
