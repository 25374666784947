import React from 'react';
import { AiOutlineCaretLeft, AiOutlineCaretRight } from 'react-icons/ai';
import './../../index.css'

export function Paginacion ({page, setPage, totalPages}:
    {page:number, setPage:React.Dispatch<React.SetStateAction<number>>, totalPages:number, marginTop?:string}) {

    const onChangePage = (isNext:boolean)=> {
        if (isNext) {
            if (totalPages > page + 1) {
                setPage((oldPage)=>++oldPage)
            }
        } else {
            if (page > 0) { 
                setPage((oldPage)=>--oldPage)
            }
        }
    }



    return (
        <div className={'paginacionBox'}>
            <div className={'paginacionContent'}>
                <button disabled={page > 0 ? false : true} onClick={()=>onChangePage(false)}><AiOutlineCaretLeft/></button>
                <div className={'paginacionNumber'}>{page + 1}</div>
                <button disabled={totalPages > page + 1 ? false : true} onClick={()=>onChangePage(true)}><AiOutlineCaretRight/></button>
            </div>
        </div>
)
}