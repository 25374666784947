import { useState } from 'react';
import { Activity, Registration } from '../../types/entities';
import { TiUserDelete } from 'react-icons/ti';
import { useSetRecoilState } from 'recoil';
import { confirmationModalInfo, ConfirmationModalType, registrationAtom, reloadRegistrationsAtom, sectionAtom } from 'src/atoms/MainAtoms';
import { act } from '@testing-library/react';
import { Section } from 'src/types/types';
import { useApi } from 'src/hooks/useApi';

export function MyRegistrationItem({ registration }: { registration: Registration }) {


    const { deleteRegistration } = useApi()
    const [deleteIcon, setDeleteIcon] = useState<boolean>(true)

    const setRegistration = useSetRecoilState<Registration | undefined>(registrationAtom)
    const setSection = useSetRecoilState(sectionAtom)
    const setModalInfo = useSetRecoilState<ConfirmationModalType>(confirmationModalInfo)
    const setReloadRegistrations = useSetRecoilState<boolean>(reloadRegistrationsAtom)


    const onSelectActivity = () => {
        setRegistration(() => registration)
        setSection(() => Section.DETALLE_INSCRIPCION)
    }

    const onDeleteRegistration = async()=> {
        await deleteRegistration(registration.id)
        setReloadRegistrations((oldValue)=>!oldValue)
    }

    const onDeleteConfirmationModal = () => {
        setModalInfo(() => { return { visible: true, text: '¿Desea eliminar su inscripción a esta actividad?', executeFun: onDeleteRegistration } })
    }

    return (
        <li onClick={onSelectActivity} onMouseEnter={() => setDeleteIcon(() => true)} onMouseLeave={() => setDeleteIcon(() => true)}>
            <p>{registration.actividad ? registration.actividad[1] : ''}</p>
            {deleteIcon ?
                <div className={'unsuscribeIconBox'} onClick={(e) => { e.stopPropagation(); onDeleteConfirmationModal(); }}>
                    <TiUserDelete />
                </div>
                : undefined}
        </li>
    )
}