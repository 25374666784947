import { ActivityType, Category } from "../types/types"
import moment from "moment"
import { conf } from "../conf"

const getActivityTypeLabel = (type: ActivityType) => {
    switch (type) {
        case ActivityType.ANUAL:
            return 'Actividades anuales'
        case ActivityType.VERANO:
            return 'Actividades de verano'
        case ActivityType.PUNTUAL:
            return 'Eventos'
        default:
            return 'Actividades anuales'
    }
}

const getCategoryLabel = (category: Category) => {
    switch (category) {
        case Category.PREBENJAMIN:
            return 'Prebenjamín'
        case Category.BENJAMIN:
            return 'Benjamín'
        case Category.ALEVIN:
            return 'Alevín'
        case Category.INFANTIL:
            return 'Infantil'
        case Category.ADULTO:
            return 'Adulto'
        default:
            return 'Seleccione su fecha de nacimiento'
    }
}


const getStringToInputDate = (date: Date) => {
    return moment(date).format(conf.dateInputFormat)
}

const getDateFromInputDate = (stringDate: string) => {
    return moment(stringDate, conf.dateInputFormat).toDate()
}

const calculateAge = (birthdate:Date| undefined)=> {
    if (birthdate) {
        const birthYear = birthdate.getFullYear()
        const currentYear = (new Date()).getFullYear()
        const age = currentYear - birthYear
        return age
    } else {
        return undefined
    }
}




export const utilMethods = { getActivityTypeLabel, getStringToInputDate, getDateFromInputDate, getCategoryLabel, calculateAge }
