import React from 'react';
import { SizeEnum } from '../../types/types';
import './../../index.css'


export function TextField({ id, label, value, setValue, size, isUpper, isError, isPass}: {
    id: string, label: string,
    value: string | undefined, setValue?: React.Dispatch<React.SetStateAction<string | undefined>>,
    size?:SizeEnum, isUpper?:boolean, isError?:boolean, isPass?:boolean}) {

    const getSize = ()=> {
        switch (size) {
            case SizeEnum.VB:
                return '100%'                
            case SizeEnum.B:
                return '70%'                
            case SizeEnum.M:
                return '50%'                
            case SizeEnum.S:
                return '20%'                
            default:
                return '70%'                
        }
    }

    return (
        <div className={'textFieldBox'}>
            <label htmlFor={id}>{label}</label>
            <div className={'fieldAndBut'}>
                <input disabled={setValue ? false : true}style={{width: getSize()}} id={id} type={isPass ? 'password' : 'text'} value={value ? value : ''} 
                    onChange={setValue ? (e) => setValue(() => isUpper? e.target.value.toUpperCase() : e.target.value ) : undefined} />
            </div>
        </div>
    )
}

