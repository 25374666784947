import React, { useState } from 'react';
import './../../index.css'
import escudo from './../../resources/escudo.png';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { sectionAtom } from '../../atoms/MainAtoms';
import { Section } from '../../types/types';
import { AiOutlineUser } from 'react-icons/ai';
import { useAuth } from '../../hooks/useAuth';
import { useApi } from '../../hooks/useApi';
import { User } from '../../types/entities';
import { FiLogOut } from 'react-icons/fi';


export function Header() {
    const [section, setSection] = useRecoilState<Section>(sectionAtom)
    const [usernameState, setUsernameState] = useState<string | undefined>(undefined)
    const [password, setPassword] = useState<string | undefined>(undefined)
    const [warning, setWarning] = useState<string | undefined>(undefined)

    const { isAuth, user, setLoginParams, cleanUserParams } = useAuth()
    const { authenticate, getUser } = useApi()



    const onSetLoginParams = async () => {
        if (usernameState) {
            const user = await getUser(usernameState)
            setLoginParams(user)
        }
    }

    const onLogin = async () => {
        setWarning(() => undefined)
        if (usernameState && password) {
            try {
                await authenticate(usernameState, password)
                await onSetLoginParams()
                setSection(() => Section.HOME)
            } catch (e) {
                setWarning(() => 'No se ha podido iniciar sesión')
            }
        }
    }

    const onLogout = () => {
        cleanUserParams()
        setSection(() => Section.HOME)

    }

    return (
        <div className={'mainBox'}>
            <div className={'logoBox'}>
                <img src={escudo} className={'logo'} alt='logo bajo aragon' />
            </div>
            <div className={'userDataBox'}>
                {isAuth ?
                    <>
                        <AiOutlineUser className={'userIcon'} />
                        <p className={'userDni'}>{user?.correo}</p>
                        <FiLogOut className={'logoutIcon'} onClick={onLogout} />
                    </>
                    :
                    <>
                        {warning ? <p className={'warningMsg'}>{warning}</p> : undefined}
                        <input type={'text'} value={usernameState} onChange={(e) => setUsernameState(e.target.value)} placeholder='Email'></input>
                        <input placeholder='Contraseña' value={password} onChange={(e) => setPassword(e.target.value)} type={'password'}></input>
                        <button className={'loginButton'} onClick={onLogin}>{'Entrar'}</button>
                    </>
                }
            </div>


        </div>
    )
}