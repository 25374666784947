import { useEffect, useState } from 'react';
import { useApi } from '../../hooks/useApi';
import './../../index.css'
import { useAuth } from '../../hooks/useAuth';
import { Registration } from '../../types/entities';
import { Paginacion } from '../molecule/Paginacion';
import { MyRegistrationItem } from '../atom/MyRegistrationItem';
import { useRecoilValue } from 'recoil';
import { reloadRegistrationsAtom } from 'src/atoms/MainAtoms';

export function MyRegistrations() {

    const { getUserRegistrations, countUserRegistrations, deleteRegistration } = useApi()
    const {user } = useAuth()


    const [page, setPage] = useState<number>(0)
    const [totalPages, setTotalPages] = useState<number>(0)
    const [totalElements, setTotalElements] = useState<number>(0)
    const [registrationList, setRegistrationList] = useState<Registration[]>([])
    const reloadRegistrations = useRecoilValue<boolean>(reloadRegistrationsAtom)

    useEffect(() => {
        onGetRegistrations()
    }, [page, reloadRegistrations])

    const onGetRegistrations = async () => {
        if (user?.id) {
            const totalElements = await countUserRegistrations(user.id)
            setTotalElements(() => totalElements)
            const registrationPage = await getUserRegistrations(user.id, page, totalElements)
            setRegistrationList(() => registrationPage.content)
            setTotalPages(() => registrationPage.paginasTotales)
        }
    }

    return (
        <>
            <div className={'myActivitiesContent'}>
                <div className={'activityList'}>
                    {registrationList.length > 0 ?
                        registrationList.map((registration) => <MyRegistrationItem registration={registration} />)
                        :
                        <p className='noElements'>{'No hay inscripciones'}</p>
                    }
                </div>
                <Paginacion page={page} setPage={setPage} totalPages={totalPages} marginTop={'0'} />
            </div>
        </>
    )
}