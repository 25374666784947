import { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { User } from 'src/types/entities';
import { useApi } from './useApi';

export interface AuthContext {
  isAuth: boolean
  user: User|undefined
  setLoginParams: (user:User) => void
  setIsAuth: React.Dispatch<React.SetStateAction<boolean>>,
  cleanUserParams: () => void
}

const AuthContext = createContext<AuthContext>({} as any)

export const useAuth = () => {
  const ctx = useContext(AuthContext)
  if (ctx === null) {
    throw new Error('useAuth() can only be used on the descendants of AuthProvider')
  } else {
    return ctx
  }
}

export const AuthProvider = ({ children }: { children: ReactNode }) => {

  const [isAuth, setIsAuth] = useState(false)
  const [user, setUser] = useState<User|undefined>(undefined)

  const setLoginParams = (user:User) => {
    setIsAuth(() => true)
    setUser(()=>user)
  }


  const cleanUserParams = () => {
    setIsAuth(() => false)
    setUser(()=>undefined)
  }


  const value: AuthContext = {
    isAuth: isAuth,
    user: user,
    setIsAuth: setIsAuth,
    setLoginParams: setLoginParams,
    cleanUserParams: cleanUserParams
  }

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  )
}
