import React, { useEffect, useState } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { Section } from 'src/types/types';
import { sectionAtom, selectedActivityAtom } from '../../atoms/MainAtoms';
import { Activity } from '../../types/entities';
import './../../index.css'

export function ActivityItem ({activity}:{activity:Activity}) {

    const setSelectedActivity = useSetRecoilState(selectedActivityAtom)
    const [disabled, setDisabled] = useState<boolean>(false)
    const setSection = useSetRecoilState(sectionAtom)


    const onSelectActivity = ()=>{
        setSelectedActivity(()=>activity)
        setSection(()=>Section.FORMULARIO)
    }


    return (
        <li  onClick={onSelectActivity}>
            {activity.name}            
        </li>
)
}