import React from 'react';
import { RecoilRoot } from 'recoil';
import { Body } from './Body';
import { ApiProvider } from './hooks/useApi';
import { AuthProvider } from './hooks/useAuth';

function App() {
  return (
    <RecoilRoot>
      <AuthProvider>
        <ApiProvider>
          <Body/>
        </ApiProvider>
      </AuthProvider>
    </RecoilRoot>
  );
}

export default App;
