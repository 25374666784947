import { atom } from "recoil";
import { Activity, Registration } from "../types/entities";
import { Section } from "../types/types";

export const selectedActivityAtom = atom<Activity|undefined>({
  key: 'selectedActivityAtom',
  default: undefined,
});

export const sectionAtom = atom<Section>({
  key: 'sectionAtom',
  default: Section.HOME
});

export const registrationAtom = atom<Registration|undefined>({
  key: 'registrationAtom',
  default: undefined,
});

export interface ConfirmationModalType {
  visible: boolean,
  text: string, 
  executeFun: any
} 
export const confirmationModalInfo = atom<ConfirmationModalType>({
  key: 'confirmationModalInfo',
  default: {visible: false, text:'', executeFun:()=>false},
});

export const reloadRegistrationsAtom = atom<boolean>({
  key: 'reloadRegistrationsAtom',
  default: false,
});
