import { useEffect, useState } from 'react';
import { useSetRecoilState } from 'recoil';
import { sectionAtom } from '../../atoms/MainAtoms';
import { useApi } from '../../hooks/useApi';
import { SizeEnum } from '../../types/types';
import './../../index.css'
import { useAuth } from '../../hooks/useAuth';
import { TextField } from '../atom/TextField';
import { MdNavigateNext } from 'react-icons/md';
import { User } from 'src/types/entities';

export function Registro() {

    const { register } = useApi()

    const [name, setName] = useState<string | undefined>(undefined)
    const [lastname, setLastname] = useState<string | undefined>(undefined)
    const [dniState, setDniState] = useState<string | undefined>(undefined)
    const [address, setAddress] = useState<string | undefined>(undefined)
    const [town, setTown] = useState<string | undefined>(undefined)
    const [mail, setMail] = useState<string | undefined>(undefined)
    const [phone, setPhone] = useState<string | undefined>(undefined)
    const [password, setPassword] = useState<string | undefined>(undefined)
    const [repeatPassword, setRepeatPassword] = useState<string | undefined>(undefined)
    const [errorMsg, setErrorMsg] = useState<string | undefined>(undefined)
    const [isCompleted, setIsCompleted] = useState<boolean>(false)

    const onRegister = async () => {
        setErrorMsg(() => undefined)
        if (name && lastname && dniState && address && town && mail && phone && password && repeatPassword) {
            if (password === repeatPassword) {
                const user: User = {
                    name: name, apellidos: lastname, correo: mail, telefono: phone, direccion: address, dni: dniState, poblacion: town,
                    password: password
                }
                try {
                    await register(user)
                    setIsCompleted(() => true)
                } catch (e) {
                    setErrorMsg(() => 'Ha ocurrido un error al realizar el registro')
                }
            } else {
                setErrorMsg(() => 'Las contraseñas no coinciden')
            }
        } else {
            setErrorMsg(() => 'Faltan campos por rellenar')
        }
    }

    return (
        <>
            {isCompleted ?
                <p className='noLoginMessage'>{'El registro se ha completado con éxito. Ahora puede iniciar sesión'}</p>
                :
                <div className={'formularioContent'}>
                    <div className={'formBox'}>
                        <div className={'formElement'}>
                            <TextField id='name' label='Nombre' value={name} setValue={setName} />
                        </div>
                        <div className={'formElement'}>
                            <TextField id='lastname' label='Apellidos' value={lastname} setValue={setLastname} size={SizeEnum.VB} />
                        </div>
                        <div className={'formElement'}>
                            <TextField id='dni' label='DNI/NIE' value={dniState} setValue={setDniState} isUpper={true} size={SizeEnum.M} />
                        </div>
                        <div className={'formElement'}>
                            <TextField id='address' label='Dirección' value={address} setValue={setAddress} size={SizeEnum.VB} />
                        </div>
                        <div className={'formElement'}>
                            <TextField id='town' label='Población' value={town} setValue={setTown} size={SizeEnum.B} />
                        </div>
                        <div className={'formElement'}>
                            <TextField id='mail' label='Email' value={mail} setValue={setMail} size={SizeEnum.VB} />
                        </div>
                        <div className={'formElement'}>
                            <TextField id='phone' label='Teléfono' value={phone} setValue={setPhone} size={SizeEnum.B} />
                        </div>
                        <div className={'formElement'}>
                            <TextField id='password' label='Contraseña' value={password} setValue={setPassword} size={SizeEnum.VB} isPass={true} />
                        </div>
                        <div className={'formElement'}>
                            <TextField id='repeatPassword' label='Repetir contraseña' value={repeatPassword} setValue={setRepeatPassword}
                                size={SizeEnum.VB} isPass={true} />
                        </div>
                    </div>
                    <button className={'continueButton'} onClick={onRegister}>{'Registrarse'}</button>
                    {errorMsg ?
                        <p className='warningMsg'>{errorMsg}</p>
                        :
                        undefined
                    }
                </div>
            }
        </>
    )
}