
export function CheckBoxField({ label, id, value, setValue}: {label: string, id: string, value: boolean, 
    setValue?: React.Dispatch<React.SetStateAction<boolean>>}) {

    return (
        <div className={'checkBoxFieldBox'}>
            <div className='fieldAndBut'>
                <input type={'checkbox'} id={id} checked={value} disabled={setValue ? false : true}
                    onChange={setValue ? (e) => setValue(() => e.target.checked) : undefined} />
                <label>{label}</label>
            </div>
        </div>
    )

}