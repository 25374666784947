import { useRecoilState, useRecoilValue } from 'recoil'
import { registrationAtom, sectionAtom } from './atoms/MainAtoms'
import { ActivitySelector } from './components/organism/ActivitySelector'
import { Formulario } from './components/organism/Formulario'
import { Header } from './components/organism/Header'
import { Menu } from './components/organism/Menu'
import './index.css'
import { Section } from './types/types'
import { IoMdArrowBack } from 'react-icons/io'
import { MyRegistrations } from './components/organism/MyRegistrations'
import { Registro } from './components/organism/Registro'
import { Registration } from './types/entities'
import { ConfirmationModal } from './components/molecule/ConfirmationModal'

export function Body() {
  const [section, setSection] = useRecoilState<Section>(sectionAtom)
  const registration = useRecoilValue<Registration | undefined>(registrationAtom)

  const getTitle = () => {
    switch (section) {
      case Section.ACTIVIDADES: return 'Seleccionar actividades'
      case Section.BAJAS: return 'Mis actividades'
      case Section.HOME: return ''
      case Section.REGISTRO: return 'Registro de usuarios'
      case Section.FORMULARIO: return 'Datos del usuario'
      case Section.DETALLE_INSCRIPCION: return 'Datos de la inscripción'
    }
  }


  const getComponent = (): JSX.Element => {
    switch (section) {
      case Section.ACTIVIDADES: return <ActivitySelector />
      case Section.BAJAS: return <MyRegistrations />
      case Section.HOME: return <Menu />
      case Section.REGISTRO: return <Registro />
      case Section.FORMULARIO: return <Formulario />
      case Section.DETALLE_INSCRIPCION: return <Formulario registration={registration} />
      default: return <ActivitySelector />
    }
  }

  const getExitButton = (): boolean => {
    switch (section) {
      case Section.ACTIVIDADES: return true
      case Section.BAJAS: return true
      case Section.HOME: return false
      case Section.REGISTRO: return true
      case Section.FORMULARIO: return true
      case Section.DETALLE_INSCRIPCION: return true
      default: return false
    }
  }

  const getExitLocation = (): Section => {
    switch (section) {
      case Section.FORMULARIO: return Section.ACTIVIDADES
      case Section.DETALLE_INSCRIPCION: return Section.BAJAS
      default: return Section.HOME
    }
  }

  return (
    <>
      <div className={'container'}>
        <Header />
        <main className={'main'}>
          <div className={'titleBox'}>
            <h1 className={'title'}>{getTitle()}</h1>
            {getExitButton() ? <button onClick={() => setSection(getExitLocation())} className={'exitBut'}><IoMdArrowBack />{'Atrás'}</button> : undefined}
          </div>
          {getComponent()}
        </main>
      </div>
      <ConfirmationModal />
    </>
  )
}
