import React, { createContext, ReactNode, useContext } from 'react';
import { conf } from '../conf';
import { Activity, LoginUser, Registration, RegistrationForm, Shift, User } from '../types/entities';
import { Page } from '../types/types';

export interface ApiContext {
  getUserRegistrations: (id: string, page: number, totalElements: number) => Promise<Page<Registration>>
  countUserRegistrations: (id: string) => Promise<number>
  countRegularActivities: () => Promise<number>
  getActivities: (type: string, page: number, totalElements: number) => Promise<Page<Activity>>
  authenticate: (username: string, password: string) => Promise<LoginUser>
  register: (user: User) => Promise<void>
  getUser: (id: string) => Promise<User>
  getActivityShifts: (activityId: string) => Promise<Shift[]>
  createRegistration: (registration: RegistrationForm) => Promise<void>
  deleteRegistration: (registrationId: string) => Promise<void>
}

const ApiContext = createContext<ApiContext>({} as any)

export const useApi = () => {
  const ctx = useContext(ApiContext)
  if (ctx === null) {
    throw new Error('useApi() can only be used on the descendants of ApiProvider')
  } else {
    return ctx
  }
}

const pageLimit = 5

const authenticate = async (username: string, password: string): Promise<LoginUser> => {
  const url = `${conf.mainApiUrl}session/authenticate`
  const options = {
    method: 'POST',
    body: JSON.stringify({ params: { db: 'bajo-aragon', login: username, password: password } }),
    headers: new Headers({
      'content-type': 'application/json'
    })
  }
  let userId
  try {
    const res = await fetch(url, options)
    const result = await res.json()
    if (result.error) {
      throw new Error('Error al iniciar sesión')
    }
    userId = result.result.uid
  } catch (e) {
    console.log(e)
    throw Error('Error al hacer login')
  }
  return { username: username, id: userId }
}

const register = async (user: User): Promise<void> => {
  const url = `${conf.mainApiUrl}${conf.registerApiUrl}`
  const options = {
    method: 'POST',
    body: JSON.stringify({
      name: user.name, apellidos: user.apellidos, dni: user.dni, telefono: user.telefono,
      direccion: user.direccion, poblacion: user.poblacion, correo: user.correo, password: user.password
    }),
    headers: new Headers({
      'content-type': 'application/json'
    })
  }
  try {
    const res = await fetch(url, options)
    const result = await res.json()
    if (result.error) {
      throw Error('Error al crear usuario')
    }
  } catch (e) {
    console.log(e)
    throw Error('Error al crear usuario')
  }
}

const createRegistration = async (registration: RegistrationForm): Promise<void> => {
  const url = `${conf.mainApiUrl}${conf.ormApiUrl}`
  const options = {
    method: 'POST',
    body: JSON.stringify({
      jsonrpc: '2.0', params: {
        model: 'aragon.inscripciones', method: 'create', args: [{
          actividad: registration.actividad, turno_id: registration.turno_id, name: registration.name, apellidos: registration.apellidos, fecha_nacimiento: registration.fecha_nacimiento,
          dni: registration.dni, direccion: registration.direccion, poblacion: registration.poblacion, correo: registration.correo, categoria: registration.categoria,
          usuario_id: registration.usuario_id, autorizacion: registration.autorizacion, numero_cuenta: registration.numero_cuenta, telefono: registration.telefono, famNum: registration.famNum,
          just_famNum: registration.just_famNum  
        }], kwargs: { context: {} }
      }
    }),
    headers: new Headers({
      'content-type': 'application/json'
    })
  }
  try {
    const res = await fetch(url, options)
    const result = await res.json()
    if (result.error) {
      throw Error('Error al crear la inscripción')
    }
  } catch (e) {
    console.log(e)
    throw Error('Error al crear la inscripción')
  }
}

const deleteRegistration = async (registrationId: string): Promise<void> => {
  const url = `${conf.mainApiUrl}${conf.ormApiUrl}`
  const options = {
    method: 'POST',
    body: JSON.stringify({
      jsonrpc: '2.0', params: {
        model: 'aragon.inscripciones', method: 'unlink', args: [registrationId], kwargs: { context: {} }
      }
    }),
    headers: new Headers({
      'content-type': 'application/json'
    })
  }
  try {
    const res = await fetch(url, options)
    const result = await res.json()
    if (result.error) {
      throw Error('Error al eliminar la inscripción')
    }
  } catch (e) {
    console.log(e)
    throw Error('Error al eliminar la inscripción')
  }
}

const countRegularActivities = async (): Promise<number> => {
  const url = `${conf.mainApiUrl}${conf.ormApiUrl}`
  const options = {
    method: 'POST',
    body: JSON.stringify({ jsonrpc: '2.0', params: { model: 'aragon.actividades', method: 'search', args: [[]], kwargs: { context: {} } } }),
    headers: new Headers({
      'content-type': 'application/json'
    })
  }
  try {
    const res = await fetch(url, options)
    const resObject = await res.json()
    if (resObject.error) {
      throw Error('Error al contar las actividades ')
    }
    return resObject.result.length
  } catch (e) {
    console.log(e)
    throw Error('Error al contar las actividades ')
  }
}

const getActivities = async (type: string, page: number, totalElements: number): Promise<Page<Activity>> => {
  const url = `${conf.mainApiUrl}${conf.ormApiUrl}`
  const options = {
    method: 'POST',
    body: JSON.stringify({
      jsonrpc: '2.0', params: {
        model: 'aragon.actividades', method: 'search_read', args: [[["tipo", "=", type]]], kwargs: {
          context: {},
          limit: pageLimit, offset: (pageLimit * page)
        }
      }
    }),
    headers: new Headers({
      'content-type': 'application/json'
    })
  }
  try {
    const res = await fetch(url, options)
    const resObject = await res.json()
    if (resObject.error) {
      throw Error('Error al obtener las actividades')
    }
    return { content: resObject.result, pagina: page, paginasTotales: Math.ceil(totalElements / pageLimit) }
  } catch (e) {
    console.log(e)
    throw Error('Error al obtener las actividades')
  }
}

const getUser = async (mail: string): Promise<User> => {
  const url = `${conf.mainApiUrl}${conf.ormApiUrl}`
  const options = {
    method: 'POST',
    body: JSON.stringify({ jsonrpc: '2.0', params: { model: 'aragon.usuarios', method: 'search_read', args: [[["correo", "=", mail]]], kwargs: { context: {} } } }),
    headers: new Headers({
      'content-type': 'application/json'
    })
  }
  try {
    const res = await fetch(url, options)
    const resObject = await res.json()
    if (resObject.error || resObject.result.length === 0) {
      throw Error('Error al obtener el usuario')
    }
    return resObject.result[0];
  } catch (e) {
    console.log(e)
    throw Error('Error al obtener el usuario')
  }
}

const getActivityShifts = async (activityId: string): Promise<Shift[]> => {
  const url = `${conf.mainApiUrl}${conf.ormApiUrl}`
  const options = {
    method: 'POST',
    body: JSON.stringify({
      jsonrpc: '2.0', params: {
        model: 'aragon.turnos', method: 'search_read', args: [[["actividad_nombre.id", "=", activityId]]], kwargs: { context: {}, }
      }
    }),
    headers: new Headers({
      'content-type': 'application/json'
    })
  }
  try {
    const res = await fetch(url, options)
    const resObject = await res.json()
    if (resObject.error) {
      throw Error('Error al obtener los turnos')
    }
    return resObject.result
  } catch (e) {
    console.log(e)
    throw Error('Error al obtener los turnos')
  }
}

const getUserRegistrations = async (id: string, page: number, totalElements: number): Promise<Page<Registration>> => {
  const url = `${conf.mainApiUrl}${conf.ormApiUrl}`
  const options = {
    method: 'POST',
    body: JSON.stringify({
      jsonrpc: '2.0', params: {
        model: 'aragon.inscripciones', method: 'search_read', args: [[["usuario_id.id", "=", id]]], kwargs: {
          context: {},
          limit: pageLimit, offset: (pageLimit * page)
        }
      }
    }),
    headers: new Headers({
      'content-type': 'application/json'
    })
  }
  try {
    const res = await fetch(url, options)
    const resObject = await res.json()
    if (resObject.error) {
      throw Error('Error al obtener las inscripciones')
    }
    return { content: resObject.result, pagina: page, paginasTotales: Math.ceil(totalElements / pageLimit) }
  } catch (e) {
    console.log(e)
    throw Error('Error al obtener las inscripciones')
  }
}

const countUserRegistrations = async (id: string): Promise<number> => {
  const url = `${conf.mainApiUrl}${conf.ormApiUrl}`
  const options = {
    method: 'POST',
    body: JSON.stringify({ jsonrpc: '2.0', params: { model: 'aragon.inscripciones', method: 'search', args: [[["usuario_id.id", "=", id]]], kwargs: { context: {} } } }),
    headers: new Headers({
      'content-type': 'application/json'
    })
  }
  try {
    const res = await fetch(url, options)
    const resObject = await res.json()
    if (resObject.error) {
      throw Error('Error al contar las inscripciones ')
    }
    return resObject.result.length
  } catch (e) {
    console.log(e)
    throw Error('Error al contar las inscripciones ')
  }
}


export const ApiProvider = ({ children }: { children: ReactNode }) => {

  const value: ApiContext = {
    getActivities: getActivities,
    authenticate: authenticate,
    register: register,
    getUserRegistrations: getUserRegistrations,
    countUserRegistrations: countUserRegistrations,
    countRegularActivities: countRegularActivities,
    getUser: getUser,
    getActivityShifts: getActivityShifts,
    createRegistration: createRegistration,
    deleteRegistration: deleteRegistration
  }

  return (
    <ApiContext.Provider value={value}>
      {children}
    </ApiContext.Provider>
  )
}
