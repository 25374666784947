const mainApiUrl = '/web/'
const registerApiUrl = 'usuario'
const ormApiUrl = 'dataset/call_kw'


const dateTimeFormat = 'yyyy/MM/DD HH:mm:ss'
const dateInputFormat = 'YYYY-MM-DD'



export const conf = {mainApiUrl, ormApiUrl, registerApiUrl, dateInputFormat, dateTimeFormat}
