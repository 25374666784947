import React, { useEffect, useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { sectionAtom, selectedActivityAtom } from '../../atoms/MainAtoms';
import { useApi } from '../../hooks/useApi';
import { Category, Section, SelectOption, SizeEnum } from '../../types/types';
import './../../index.css'
import { useAuth } from '../../hooks/useAuth';
import { TextField } from '../atom/TextField';
import { MdNavigateNext } from 'react-icons/md';
import { DateField } from '../atom/DateField';
import { CategoryField } from '../atom/CategoryField';
import { Registration, Shift } from 'src/types/entities';
import { SelectField } from '../atom/SelectField';
import { utilMethods } from 'src/utils/utilMethods';
import { FileField } from '../atom/FileField';
import { CheckBoxField } from '../atom/CheckboxField';

export function Formulario({ registration }: { registration?: Registration }) {


    const setSection = useSetRecoilState(sectionAtom)

    const { user } = useAuth()
    const { getUser, getActivityShifts, createRegistration } = useApi()

    const selectedActivity = useRecoilValue(selectedActivityAtom)

    const [shiftList, setShiftList] = useState<SelectOption[]>([])
    const [name, setName] = useState<string | undefined>(undefined)
    const [lastname, setLastname] = useState<string | undefined>(undefined)
    const [birthdate, setBirthdate] = useState<Date | undefined>(undefined)
    const [dniState, setDniState] = useState<string | undefined>(undefined)
    const [address, setAddress] = useState<string | undefined>(undefined)
    const [town, setTown] = useState<string | undefined>(undefined)
    const [mail, setMail] = useState<string | undefined>(undefined)
    const [phone, setPhone] = useState<string | undefined>(undefined)
    const [numeroCuenta, setNumeroCuenta] = useState<string | undefined>(undefined)
    const [shiftId, setShiftId] = useState<string | undefined>(undefined)
    const [category, setCategory] = useState<string>('')
    const [authorization, setAuthorization] = useState<File | undefined>()
    const [authorizationB64, setAuthorizationB64] = useState<string | undefined>()
    const [famNum, setFamNum] = useState<boolean>(false)
    const [justFamiliaNum, setJustFamiliaNum] = useState<File | undefined>()
    const [justFamiliaNumB64, setJustFamiliaNumB64] = useState<string | undefined>()

    const [errorMsg, setErrorMsg] = useState<string | undefined>(undefined)
    const [isCompleted, setIsCompleted] = useState<boolean>(false)


    useEffect(() => {
        onGetShifts()
        if (registration) {
            onGetRegistration()
        } else {
            onGetUser()
        }
    }, [registration])

    const onGetRegistration = () => {
        if (registration) {
            setName(() => registration.name)
            setLastname(() => registration.apellidos)
            setDniState(() => registration.dni)
            setAddress(() => registration.direccion)
            setTown(() => registration.poblacion)
            setMail(() => registration.correo)
            setPhone(() => registration.telefono)
            setBirthdate(() => utilMethods.getDateFromInputDate(registration.fecha_nacimiento))
            setShiftId(() => registration.turn_id)
            setCategory(() => registration.categoria)
            setNumeroCuenta(() => registration.numero_cuenta)
            setAuthorizationB64(() => registration.autorizacion)
            setFamNum(() => registration.famNum)
            setJustFamiliaNumB64(() => registration.just_famNum)
        }
    }

    const onGetUser = async () => {
        if (user) {
            setName(() => user.name)
            setLastname(() => user.apellidos)
            setDniState(() => user.dni)
            setAddress(() => user.direccion)
            setTown(() => user.poblacion)
            setMail(() => user.correo)
            setPhone(() => user.telefono)
        }
    }


    const onGetShifts = async () => {
        if (selectedActivity) {
            const shiftList = await getActivityShifts(selectedActivity.id)
            const shiftOptionList: SelectOption[] = shiftList.map((shift) => { return { value: shift.id, label: shift.municipio_id } })
            setShiftList(() => shiftOptionList)
        }
        if (registration) {
            const shiftList = await getActivityShifts(registration.act_id)
            const shiftOptionList: SelectOption[] = shiftList.map((shift) => { return { value: shift.id, label: shift.municipio_id } })
            setShiftList(() => shiftOptionList)
        }
    }

    const onCreateRegistration = async () => {
        setErrorMsg(() => undefined)
        if (user?.id && name && lastname && birthdate && dniState && address && town && mail && phone && selectedActivity?.id && shiftId &&
            (category === utilMethods.getCategoryLabel(Category.ADULTO) || authorization) && numeroCuenta && (!famNum || justFamiliaNum)) {
            try {
                let authB64 = undefined
                if (category !== Category.ADULTO.toString()) {
                    if (authorization) {
                        authB64 = await getBase64(authorization)
                    }
                }
                let justFamNumB64 = undefined
                if (famNum) {
                    if (justFamiliaNum) {
                        justFamNumB64 = await getBase64(justFamiliaNum)
                    }
                }

                await createRegistration({
                    usuario_id: user.id, actividad: selectedActivity.id, turno_id: shiftId, name: name, apellidos: lastname,
                    fecha_nacimiento: utilMethods.getStringToInputDate(birthdate), dni: dniState, direccion: address, poblacion: town, correo: mail, categoria: category,
                    autorizacion: authB64 ? authB64 : undefined, numero_cuenta: numeroCuenta, famNum: famNum, telefono: phone,
                    just_famNum: justFamNumB64 ? justFamNumB64 : undefined
                })
                setIsCompleted(() => true)
            } catch (e) {
                setErrorMsg(() => 'Ha ocurrido un error al realizar el registro')
            }
        } else {
            setErrorMsg(() => 'Faltan campos por rellenar')
        }
    }

    const getBase64 = (file: File): Promise<string> => {
        return new Promise(resolve => {
            // Make new FileReader
            let reader = new FileReader();

            // Convert the file to base64 text
            reader.readAsDataURL(file);

            // on reader load somthing...
            reader.onload = () => {
                // Make a fileInfo Object
                console.log("Called", reader);
                const baseURL = reader.result as string
                const onlyCodeBase64 = baseURL?.slice(baseURL.search('base64,') + 7)
                resolve(onlyCodeBase64);
            };
        });
    };

    return (
        <>
            {isCompleted ?
                <p className='noLoginMessage'>{'Se ha registrado correctamente su solicitud'}</p>
                :
                <div className={'formularioContent'}>
                    <div className={'formBox'}>
                        <div className={'formElement'}>
                            <SelectField id='shift' label='Municipio de la actividad' defaultMessage='Seleccione un municipio' optionsList={shiftList} value={shiftId}
                                setValue={registration ? undefined : setShiftId} />
                        </div>
                        <div className={'formElement'}>
                            <TextField id='name' label='Nombre' value={name} setValue={registration ? undefined : setName} />
                        </div>
                        <div className={'formElement'}>
                            <TextField id='lastname' label='Apellidos' value={lastname} setValue={registration ? undefined : setLastname} size={SizeEnum.VB} />
                        </div>
                        <div className={'formElement'}>
                            <TextField id='dni' label='DNI/NIE (Opcional)' value={dniState} setValue={registration ? undefined : setDniState} isUpper={true} size={SizeEnum.M} />
                        </div>
                        <div className={'formElement'}>
                            <DateField id='birthdate' label='Fecha de nacimiento' value={birthdate} setValue={registration ? undefined : setBirthdate} size={SizeEnum.M} />
                        </div>
                        <div className={'formElement'}>
                            <CategoryField birthdate={birthdate} category={category} setCategory={registration ? undefined : setCategory} />
                        </div>
                        <div className={'formElement'}>
                            <TextField id='address' label='Dirección' value={address} setValue={registration ? undefined : setAddress} size={SizeEnum.VB} />
                        </div>
                        <div className={'formElement'}>
                            <TextField id='town' label='Población' value={town} setValue={registration ? undefined : setTown} size={SizeEnum.B} />
                        </div>
                        <div className={'formElement'}>
                            <TextField id='mail' label='Email' value={mail} setValue={registration ? undefined : setMail} size={SizeEnum.VB} />
                        </div>
                        <div className={'formElement'}>
                            <TextField id='phone' label='Teléfono' value={phone} setValue={registration ? undefined : setPhone} size={SizeEnum.B} />
                        </div>
                        <div className={'formElement'}>
                            <TextField id='numeroCuenta' label='Número de cuenta bancaria' value={numeroCuenta} setValue={registration ? undefined : setNumeroCuenta}
                                size={SizeEnum.VB} />
                        </div>
                        {(!birthdate || category === utilMethods.getCategoryLabel(Category.ADULTO)) ?
                            undefined
                            :
                            <div className={'formElement'}>
                                <FileField id='authorization' label='Autorización' value={authorizationB64} setValue={registration ? undefined : setAuthorization} downFile='autorizacion.pdf' />
                            </div>
                        }
                        <div className={'formElement'}>
                            <CheckBoxField id='famNum' label='Pertenece a familia numerosa' value={famNum} setValue={registration ? undefined : setFamNum}/>
                        </div>
                        {famNum ?
                            <div className={'formElement'}>
                                <FileField id='justFamiliaNum' label='Justificante familia numerosa' value={justFamiliaNumB64} setValue={registration ? undefined : setJustFamiliaNum} downFile='justificanteFamNum.pdf' />
                            </div>
                            :
                            undefined
                        }
                    </div>
                    {registration ?
                        undefined
                        :
                        <button className={'continueButton'} onClick={onCreateRegistration}>{'Enviar'}<MdNavigateNext /></button>
                    }
                    {errorMsg ?
                        <p className='warningMsg'>{errorMsg}</p>
                        :
                        undefined
                    }
                </div>
            }
        </>
    )
}