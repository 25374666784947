import React from 'react';
import { SizeEnum } from '../../types/types';
import './../../index.css'
import { utilMethods } from '../../utils/utilMethods';


export function DateField({ id, label, value, setValue, size, isUpper, isError}: {
    id: string, label: string,
    value: Date | undefined, setValue?: React.Dispatch<React.SetStateAction<Date | undefined>>,
    size?:SizeEnum, isUpper?:boolean, isError?:boolean}) {

    const getSize = ()=> {
        switch (size) {
            case SizeEnum.VB:
                return '100%'                
            case SizeEnum.B:
                return '70%'                
            case SizeEnum.M:
                return '50%'                
            case SizeEnum.S:
                return '20%'                
            default:
                return '70%'                
        }
    }

    return (
        <div className={'textFieldBox'}>
            <label htmlFor={id}>{label}</label>
            <div className={'fieldAndBut'}>
                <input disabled={setValue ? false : true} style={{width: getSize()}} id={id} type={'date'} value={value ? utilMethods.getStringToInputDate(value) : ''} 
                    onChange={setValue ? (e) => setValue(() => utilMethods.getDateFromInputDate(e.target.value)) : undefined}
                    onFocus={(e)=>e.target.showPicker()} />
            </div>
        </div>
    )
}

