import React, { useEffect } from 'react';
import { SizeEnum } from '../../types/types';
import '../../index.css'
import { calculateNewValue } from '@testing-library/user-event/dist/utils';
import { utilMethods } from 'src/utils/utilMethods';


export function CategoryField({ birthdate, category, setCategory }:
    { birthdate: Date | undefined, category: string, setCategory: React.Dispatch<React.SetStateAction<string>> | undefined }) {

    useEffect(() => {
        if (setCategory) {
            setCategory(getCategory())
        }
    }, [birthdate])


    const getCategory = () => {
        const age = utilMethods.calculateAge(birthdate)
        switch (age) {
            case undefined:
                return 'Escoja una fecha de nacimiento'
            case 0:
            case 1:
            case 2:
            case 3:
            case 4:
            case 5:
            case 5:
            case 6:
            case 7:
                return 'Prebenjamín'
            case 8:
            case 9:
                return 'Benjamín'
            case 10:
            case 11:
                return 'Alevín'
            case 12:
            case 13:
                return 'Infantil'
            default:
                return 'Adulto'
        }
    }

    return (
        <div className={'textFieldBox'}>
            <label>{'Categoría'}</label>
            <div className={'fieldAndNum categoryField'}>
                <p className={setCategory ? '' : 'disabledField'}>{category}</p>
            </div>
        </div>
    )
}

