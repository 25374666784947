import React, { } from 'react';
import './../../index.css'
import { SelectOption } from 'src/types/types';

export function SelectField({ id, label, defaultMessage, optionsList, value, setValue }: { id: string, label: string, defaultMessage: string, 
    optionsList: SelectOption[], value: string | undefined, setValue: React.Dispatch<React.SetStateAction<string | undefined>> | undefined }) {

    return (
        <div className={'textFieldBox'}>
            <label htmlFor={id}>{label}</label>
            <div className={'fieldAndBut'}>
                <select id={id} disabled={setValue ? false : true} onChange={setValue ? (e)=>setValue(e.target.value) : undefined} value={value}>
                    <option>{defaultMessage}</option>
                    {optionsList.map((option) => <option value={option.value}>{option.label}</option>)}
                </select>
            </div>
        </div>

    )
}